const PabFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11
    0C17.0751 0 22 4.92487 22 11Z"
      fill="white"
    />
    <path
      d="M11 0C12.4445 -6.31429e-08 13.8749 0.284524 15.2095 0.837325C16.5441 1.39013 17.7567 2.20038
    18.7782 3.22183C19.7996 4.24327 20.6099 5.4559 21.1627 6.79048C21.7155 8.12506 22 9.55546 22 11H11L11
    0Z"
      fill="#D52B1E"
    />
    <path
      d="M11 22C9.55546 22 8.12506 21.7155 6.79048 21.1627C5.4559 20.6099 4.24327 19.7996 3.22183
    18.7782C2.20038 17.7567 1.39013 16.5441 0.837326 15.2095C0.284524 13.8749 -4.59169e-08 12.4445 0
    11H11L11 22Z"
      fill="#032B7D"
    />
    <path
      d="M15.5 12L16.2858 14.4184H18.8287L16.7715 15.9131L17.5572 18.3316L15.5 16.8369L13.4428
    18.3316L14.2285 15.9131L12.1713 14.4184H14.7142L15.5 12Z"
      fill="#D52B1E"
    />
    <path
      d="M6.5 3L7.2858 5.41844H9.8287L7.77145 6.91312L8.55725 9.33156L6.5 7.83688L4.44275 9.33156L5.22855
    6.91312L3.1713 5.41844H5.7142L6.5 3Z"
      fill="#032B7D"
    />
  </svg>

);

export default PabFlagSVG;
