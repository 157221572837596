import {
  Fragment, useContext, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { AppContext } from 'contexts/AppContext';
import { CurrencyActionTypes } from 'contexts/reducers/curency.reducer';
import { ECurrency } from 'types/enum/currency';
import useGetAllCurrencies from 'hooks/useGetAllCurrencies';
import { setPref } from '../../utils/prefs';
import {
  UsdFlagSVG,
  ArsFlagSVG,
  MxnFlagSVG,
  CopFlagSVG,
  ClpFlagSVG,
  UyuFlagSVG,
  PygFlagSVG,
  CrcFlagSVG,
  BobFlagSVG,
  PabFlagSVG,
  PenFlagSVG,
  WorldSVG,
} from './svg';

const currencyFlagMap: { [key in ECurrency]: React.ComponentType } = {
  [ECurrency.USD]: UsdFlagSVG,
  [ECurrency.ARS]: ArsFlagSVG,
  [ECurrency.MXN]: MxnFlagSVG,
  [ECurrency.COP]: CopFlagSVG,
  [ECurrency.CLP]: ClpFlagSVG,
  [ECurrency.UYU]: UyuFlagSVG,
  [ECurrency.PYG]: PygFlagSVG,
  [ECurrency.PEN]: PenFlagSVG,
  [ECurrency.CRC]: CrcFlagSVG,
  [ECurrency.BOB]: BobFlagSVG,
  [ECurrency.PAB]: PabFlagSVG,
};

export default function CurrencyMenu(): JSX.Element | null {
  const { dispatch, state } = useContext(AppContext);
  const defaultCurrency = state?.currency?.selected as ECurrency;
  const timeoutDuration = 0;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);
  const [mobileTouch, setMobileTouch] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<ECurrency>(defaultCurrency);
  const SelectedFlagComponent = currencyFlagMap[selectedCurrency] ?? WorldSVG;
  let timeout: NodeJS.Timeout;
  const { ready, availableCurrencies } = useGetAllCurrencies();

  const toggleMenu = () => {
    setOpenState((openStatePrev) => !openStatePrev);
    buttonRef.current?.click();
    buttonRef.current?.blur();
  };

  const onHover = (open: boolean, action: string) => {
    if (mobileTouch) return;

    if (
      (!open && !openState && action === 'onMouseEnter')
      || (open && openState && action === 'onMouseLeave')
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleMenu(), timeoutDuration);
    }
  };

  const onMobileTouch = (action: string) => {
    if (action === 'onTouchStart') {
      setMobileTouch(true);
    } else if (action === 'onMouseUp') {
      setMobileTouch(false);
    }
  };

  const handleClick = (open: boolean) => {
    setOpenState(!open);
    clearTimeout(timeout);
  };

  const handleCurrencySelect = (currency: ECurrency) => {
    setSelectedCurrency(currency);
    setPref(currency);
    dispatch({ type: CurrencyActionTypes.select, selected: currency });
    toggleMenu();
  };

  useEffect(() => {
    if (state.currency.selected === selectedCurrency) return;
    setSelectedCurrency(state.currency.selected as ECurrency);
  }, [state.currency.selected, selectedCurrency]);

  if (!ready || !availableCurrencies?.length || !selectedCurrency) return <div />;

  return (
    <Popover.Group as="div" className="flex space-x-12 flex-none">
      <Popover className="relative">
        {({ open }) => (
          <div
            onMouseEnter={() => onHover(open, 'onMouseEnter')}
            onMouseLeave={() => onHover(open, 'onMouseLeave')}
            onTouchStart={() => onMobileTouch('onTouchStart')}
            onMouseUp={() => onMobileTouch('onMouseUp')}
          >
            <Popover.Button ref={buttonRef}>
              <div
                className={classNames(
                  open
                    ? 'text-gray-900 dark:text-white'
                    : 'text-gray-500 dark:text-gray-300',
                  'group rounded-md inline-flex items-center text-base',
                  'font-medium hover:text-gray-900 dark:hover:text-white focus:outline-none',
                  'focus-visible:ring-2 focus-visible:ring-offset-4 focus-visible:ring-primary-500',
                  'gap-x-2 cursor-pointer',
                )}
                onClick={() => handleClick(open)}
              >
                <SelectedFlagComponent />
                {selectedCurrency}
                <ChevronDownIcon
                  className={classNames(
                    open
                      ? 'text-gray-600 dark:text-white'
                      : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500 dark:group-hover:text-white dark:text-gray-300',
                  )}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-50 -ml-4 mt-3 transform w-screen sm:max-w-[122px]"
                static
              >
                <div
                  className="rounded-lg bg-[#060606] text-white overflow-hidden"
                  style={{ boxShadow: '0px 16px 16px 0px rgba(0, 0, 0, 0.08)' }}
                >
                  <div className="relative grid">
                    {availableCurrencies.map((currency: ECurrency) => {
                      const FlagComponent = currencyFlagMap[currency] ?? WorldSVG;
                      return (
                        <button
                          type="button"
                          className={classNames(
                            'm-0 rounded-none py-3 px-7 flex gap-x-2',
                            'cursor-pointer text-base hover:bg-[#2C2C31]',
                          )}
                          key={currency}
                          onClick={() => handleCurrencySelect(currency)}
                        >
                          <FlagComponent />
                          <span>{currency}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </Popover.Group>
  );
}
