const MxnFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22
    4.92487 22 11Z"
      fill="white"
    />
    <path d="M15.95 20.9L15.95 1.1C17.325 1.925 22 4.675 22 11.275C22 17.875 17.05 20.35 15.95 20.9Z" fill="#E92B2B" />
    <path
      d="M6.05 1.1L6.05 20.9C4.675 20.075 3.31127e-07 17.325 1.21336e-07 10.725C2.0004e-07 4.125 4.95 1.65 6.05
    1.1Z"
      fill="#32AB45"
    />
    <path
      d="M9.35 8.8L10.175 8.25L10.725 8.8C11.55 8.8 12.0271 9.00215 12.375 9.35C12.925 9.9 12.925 10.3583 12.925
    10.725H11.55L12.375 12.1C11.9167 12.1 10.615 11.66 10.175 11C9.735 10.34 9.80833 9.625 9.9 9.35L9.35
    8.8Z"
      fill="#FFBC0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9376 10.4488C8.93694 10.2215 8.75251 10.0375 8.52511
    10.0375C8.29729 10.0375 8.11261 10.2222 8.11261 10.45H8.52511C8.11261 10.45 8.11261 10.45 8.11261 10.45L8.11261
    10.4509L8.11261 10.4521L8.11263 10.4553L8.11274 10.4645C8.11286 10.472 8.11309 10.4821 8.11351 10.4946C8.11434
    10.5196 8.11594 10.5544 8.11903 10.5976C8.12518 10.6837 8.13729 10.8041 8.16119 10.9475C8.20866 11.2323 8.30437
    11.6184 8.49991 12.0095C8.69625 12.4022 8.99724 12.8074 9.45645 13.1135C9.91824 13.4214 10.5154 13.6125 11.2751
    13.6125C12.0348 13.6125 12.632 13.4214 13.0937 13.1135C13.5529 12.8074 13.8539 12.4021 14.0502 12.0095C14.2457
    11.6184 14.3414 11.2323 14.3889 10.9475C14.4128 10.8041 14.4249 10.6837 14.431 10.5975C14.4341 10.5544 14.4357
    10.5196 14.4365 10.4946C14.4369 10.4821 14.4372 10.472 14.4373 10.4645L14.4374 10.4552L14.4374 10.4521L14.4374
    10.4509C14.4374 10.4509 14.4374 10.45 14.0249 10.45L14.4374 10.45C14.4374 10.2222 14.2527 10.0375 14.0249
    10.0375C13.7971 10.0375 13.6124 10.2222 13.6124 10.45M13.6124 10.45L13.6124 10.4513C13.6123 10.4542 13.6122 10.4595
    13.612 10.4672C13.6115 10.4824 13.6104 10.5068 13.6081 10.5388C13.6035 10.603 13.5941 10.6975 13.5751
    10.8119C13.5366 11.0427 13.4605 11.3441 13.3123 11.6405C13.1649 11.9354 12.9503 12.2176 12.6361 12.4271C12.3245
    12.6349 11.8904 12.7875 11.2751 12.7875C10.6598 12.7875 10.2257 12.6349 9.91408 12.4271C9.59985 12.2176 9.38522
    11.9353 9.23781 11.6405C9.08959 11.3441 9.01343 11.0427 8.97496 10.8119C8.95589 10.6975 8.94652 10.603 8.94193
    10.5388C8.93964 10.5067 8.93856 10.4824 8.93805 10.4671C8.93779 10.4595 8.93768 10.4542 8.93764 10.4512L8.9376
    10.4488"
      fill="#32AB45"
    />
  </svg>
);

export default MxnFlagSVG;
