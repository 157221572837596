/* eslint-disable max-len */
const PenFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11Z" fill="white" />
    <path d="M15.95 20.9L15.95 1.1C17.325 1.925 22 4.675 22 11.275C22 17.875 17.05 20.35 15.95 20.9Z" fill="#E92B2B" />
    <path d="M6.05 1.1L6.05 20.9C4.675 20.075 -1.13636e-07 17.325 1.21336e-07 10.725C2.0004e-07 4.125 4.95 1.65 6.05 1.1Z" fill="#E92B2B" />
  </svg>
);

export default PenFlagSVG;
