const CopFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11.0015C22 17.0758 17.0751 22 11 22C4.92487 22 0 17.0758 0 11.0015C0 4.92724 4.92487 0.00306035
    11 0.00306035C17.0751 0.00306035 22 4.92724 22 11.0015Z"
      fill="#0052B4"
    />
    <path
      d="M1.19863 15.9508H20.8246C19.788 17.8756 17.325 22 10.725 22C4.12501 22 1.74863 17.0507 1.19863
    15.9508Z"
      fill="#E92B2B"
    />
    <path
      d="M22 11.0015H0C0 5.22736 4.72715 0.153351 10.7249 0.00338686C16.8746 -0.150373 22 4.95239 22
    11.0015Z"
      fill="#FFDA44"
    />
  </svg>
);

export default CopFlagSVG;
