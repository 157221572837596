const BobFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 22.0003C4.92487 22.0003 -2.65552e-07 17.0754 0 11.0003C2.65552e-07 4.92512 4.92487
    0.000261102 11 0.000261367C17.0751 0.000261633 22 4.92512 22 11.0003C22 17.0754 17.0751 22.0003
    11 22.0003Z"
      fill="#FFDA44"
    />
    <path
      d="M0.511676 14.3262L21.4884 14.3262C20.6184 17.3202 17.2136 22.0219 10.723 22.0006C4.23239
    21.9792 0.443172 15.8054 0.511676 14.3262Z"
      fill="#32AB45"
    />
    <path
      d="M21.4884 7.67497L0.511668 7.67497C0.882941 6.28855 3.45854 0.0483503 11 0.000261367C18.5415
    -0.0478276 21.3652 6.55465 21.4884 7.67497Z"
      fill="#D52B1E"
    />
  </svg>
);

export default BobFlagSVG;
