const ArsFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 22C4.92487 22 -2.65553e-07 17.0751 0 11C2.65553e-07 4.92487 4.92487 -2.65553e-07 11 0C17.0751
    2.65553e-07 22 4.92487 22 11C22 17.0751 17.0751 22 11 22Z"
      fill="white"
    />
    <path
      d="M21.0914 6.6L0.918217 6.6C0.918217 6.6 3.3 -4.28399e-07 11 0C18.7 4.28399e-07 21.0914 6.6 21.0914
    6.6Z"
      fill="#338AF3"
    />
    <path
      d="M0.925686 15.4L21.0727 15.4C21.0727 15.4 18.7 22 11 22C3.3 22 0.925686 15.4 0.925686
    15.4Z"
      fill="#338AF3"
    />
    <path
      d="M12.2396 7.70526L12.3304 9.4663L14.0553 8.9963L13.074 10.459L14.7653 11.077L13.0867 11.6827L14.0983
    13.1527L12.3636 12.6699L12.3092 14.4304L11.181 13.0437L10.0813 14.4222L9.99051 12.6612L8.26565 13.1312L9.24693
    11.6685L7.55569 11.0504L9.23426 10.4448L8.22263 8.97478L9.95734 9.45754L10.0117 7.69704L11.14 9.08378L12.2396
    7.70526Z"
      fill="#FFDA44"
    />
  </svg>
);

export default ArsFlagSVG;
