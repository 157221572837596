const CrcFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9999 11C21.9999 17.0751 17.0751 21.9999 11 21.9999C4.92485 21.9999
    0 17.0751 0 11C0 4.92485 4.92485 0 11 0C17.0751 0 21.9999 4.92485 21.9999 11Z"
      fill="white"
    />
    <path
      d="M0.692566 7.14997H21.3073C22.2749 9.89996 22.2749 12.375 21.0846 15.3999H0.915293C0.228514
    13.6929 0.0136574 12.73 0 11C0.0018053 9.43012 0.274999 8.54486 0.692566 7.14997Z"
      fill="#D52B1E"
    />
    <path
      d="M2.63371 3.85007C3.74854 2.59714 4.81006 1.59382 6.59997 0.825142C8.20637 0.135274 10.1749 0 11
    0C11.825 0 13.9871 0.0582279 15.6749 0.825142C17.3826 1.60106 18.4534 2.75534 19.4056
    3.85007H2.63371Z"
      fill="#032B7D"
    />
    <path
      d="M19.4054 18.15C18.2906 19.4029 17.2291 20.4062 15.4391 21.1749C13.8328 21.8648 11.8642 22
    11.0391 22C10.2141 22 8.05201 21.9418 6.36418 21.1749C4.65654 20.399 3.58572 19.2447 2.63357
    18.15L19.4054 18.15Z"
      fill="#032B7D"
    />
  </svg>
);

export default CrcFlagSVG;
