const PygFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 22.0003C4.92487 22.0003 -2.65552e-07 17.0754 0 11.0003C2.65552e-07 4.92513 4.92487 0.000261114
    11 0.00026138C17.0751 0.000261645 22 4.92513 22 11.0003C22 17.0754 17.0751 22.0003 11 22.0003Z"
      fill="white"
    />
    <path
      d="M0.511554 14.3258L21.4883 14.3258C20.6182 17.3199 17.2135 22.0216 10.7229 22.0002C4.23227 21.9788 0.443049
    15.8051 0.511554 14.3258Z"
      fill="#0038A8"
    />
    <path
      d="M21.4883 7.67464L0.511546 7.67464C0.882819 6.28822 3.45854 0.0483503 11 0.00026138C18.5415 -0.0478275
    21.3651 6.55432 21.4883 7.67464Z"
      fill="#D52B1E"
    />
    <path
      d="M11 9.72119L11.2782 10.6174L12.2165 10.605L11.4501 11.1465L11.7518 12.0351L11 11.4735L10.2482
    12.0351L10.5499 11.1465L9.78353 10.605L10.7218 10.6174L11 9.72119Z"
      fill="#FFDA44"
    />
    <path
      d="M13.814 11.0003C13.814 12.5544 12.5541 13.8143 11 13.8143C9.4459 13.8143 8.18605 12.5544 8.18605
    11.0003C8.18605 9.4462 9.4459 8.18635 11 8.18635C12.5541 8.18635 13.814 9.4462 13.814 11.0003ZM8.59941
    11.0003C8.59941 12.3261 9.67419 13.4009 11 13.4009C12.3258 13.4009 13.4006 12.3261 13.4006 11.0003C13.4006 9.6745
    12.3258 8.59972 11 8.59972C9.67419 8.59972 8.59941 9.6745 8.59941 11.0003Z"
      fill="#303030"
    />
    <path
      d="M13.1086 11.9246C13.2661 11.5653 13.3292 11.1717 13.2919 10.7812C13.2545 10.3906 13.118 10.0161 12.8953
    9.69317C12.6725 9.37022 12.371 9.10954 12.0192 8.93587C11.6674 8.7622 11.2771 8.6813 10.8853 8.70085C10.4935 8.7204
    10.1131 8.83974 9.78038 9.04756C9.44763 9.25538 9.17351 9.54478 8.98403 9.88829C8.79454 10.2318 8.69597 10.618
    8.69768 11.0103C8.69939 11.4027 8.80132 11.788 8.99379 12.1299C9.1816 12.1898 9.23761 12.1427 9.29044
    11.9628C9.12643 11.6715 9.03957 11.3432 9.03811 11.0089C9.03666 10.6746 9.12065 10.3454 9.28212 10.0527C9.44358 9.76
    9.67717 9.51339 9.96072 9.3363C10.2443 9.15922 10.5684 9.05752 10.9022 9.04086C11.2361 9.02421 11.5687 9.09314
    11.8685 9.24113C12.1682 9.38912 12.4252 9.61126 12.615 9.88645C12.8048 10.1617 12.9211 10.4808 12.953
    10.8136C12.9848 11.1464 12.931 11.4817 12.7968 11.7879C12.8704 11.9375 12.9379 11.9691 13.1086
    11.9246Z"
      fill="#303030"
    />
    <path
      d="M9.18419 11.0004C9.18419 11.2422 9.23249 11.4816 9.32626 11.7045C9.42003 11.9274 9.55738 12.1293 9.73025
    12.2984C9.90311 12.4675 10.108 12.6004 10.3329 12.6892C10.5578 12.7781 10.7982 12.8211 11.04 12.8158C11.2818 12.8105
    11.52 12.7569 11.7408 12.6583C11.9616 12.5596 12.1605 12.4179 12.3257 12.2413C12.491 12.0648 12.6193 11.857 12.7032
    11.6302C12.7871 11.4034 12.8248 11.1621 12.8142 10.9205C12.7907 10.7445 12.3895 10.7527 12.3976 10.9388C12.4058
    11.125 12.3768 11.3108 12.3122 11.4856C12.2475 11.6603 12.1487 11.8204 12.0214 11.9564C11.894 12.0924 11.7408
    12.2016 11.5707 12.2776C11.4006 12.3536 11.2171 12.3949 11.0308 12.399C10.8446 12.4031 10.6594 12.3699 10.4861
    12.3015C10.3128 12.233 10.155 12.1307 10.0218 12.0004C9.88863 11.8701 9.78281 11.7145 9.71058 11.5428C9.63834
    11.3711 9.60112 11.1867 9.60112 11.0004C9.60112 10.814 9.2093 10.7445 9.18419 11.0004Z"
      fill="#32AB45"
    />
  </svg>
);

export default PygFlagSVG;
