const ClpFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22
    4.92487 22 11Z"
      fill="white"
    />
    <path d="M11 11L11 0C4.95 0 0 4.95 0 11H11Z" fill="#0052B4" />
    <path d="M22 11H0C0 17.05 4.95 22 11 22C17.05 22 22 17.05 22 11Z" fill="#D80027" />
    <path
      d="M6.875 3.3L7.67764 5.77026H10.275L8.17369 7.29697L8.97633 9.76724L6.875 8.24053L4.77367 9.76724L5.5763
    7.29697L3.47497 5.77026H6.07236L6.875 3.3Z"
      fill="white"
    />
  </svg>
);

export default ClpFlagSVG;
