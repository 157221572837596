const UsdFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9762 0.412588C13.8176 0.500423 13.6346 0.53414 13.455 0.508615L11.3916 0.316561C11.3225
    0.321522 11.2576 0.351292 11.2088 0.400368C11.16 0.449444 11.1307 0.514508 11.1262 0.583519C11.1857
    0.849691 11.1597 1.12782 11.052 1.37844C10.9443 1.62906 10.7604 1.83945 10.5262 1.97975L8.79543
    3.11094C8.73005 3.17816 8.66275 3.24346 8.66275 3.30299V3.9675C8.65842 4.01699 8.6367 4.06336 8.60153
    4.09848C8.56636 4.1336 8.51999 4.15523 8.47044 4.15956H8.40312C8.19294 3.97428 7.99986 3.77053 7.82619
    3.55075C7.78921 3.51278 7.74633 3.48101 7.69924 3.45664C7.50254 3.35207 7.27264 3.32863 7.05884
    3.39134L6.72615 3.48737C6.49641 3.55235 6.28229 3.6631 6.09655 3.81295C5.91081 3.9628 5.75736 4.14864
    5.64545 4.35929L5.46657 4.69154C5.45219 4.72199 5.44423 4.75509 5.44328 4.78874C5.44233 4.8224 5.44839
    4.85589 5.46103 4.8871C5.47368 4.91831 5.49269 4.94657 5.51681 4.9701C5.54092 4.99362 5.56968 5.01191
    5.60122 5.0238L6.19928 5.21585C6.23711 5.23257 6.26964 5.25929 6.29336 5.29314C6.31709 5.32699 6.33106
    5.36668 6.33383 5.40791V5.93989C6.33383 6.05705 6.38581 6.12234 6.48774 6.13195L6.61657 6.15115L6.86653
    6.20109C7.0576 6.2397 7.23151 6.33785 7.36317 6.48142C7.49483 6.62499 7.57747 6.80656 7.59924
    7.00003C7.60393 7.04938 7.62574 7.09555 7.66083 7.1306C7.69593 7.16565 7.74213 7.18739 7.79154
    7.19208H10.9224C10.9572 7.1888 10.9923 7.19292 11.0255 7.20418C11.0586 7.21543 11.089 7.23357 11.1147
    7.25739L11.8474 8.05632C11.8729 8.08025 11.9032 8.09844 11.9364 8.10971C11.9696 8.12097 12.0048 8.12503
    12.0397 8.12163H12.7723C12.8217 8.12632 12.868 8.14806 12.9031 8.18311C12.9382 8.21816 12.9599 8.26433
    12.9646 8.31368V8.78037C12.9622 8.80618 12.9656 8.8322 12.9745 8.85657C12.9833 8.88094 12.9974 8.90308
    13.0158 8.9214C13.0341 8.93973 13.0563 8.95381 13.0807 8.96262C13.1051 8.97144 13.1311 8.97478 13.1569
    8.97242L14.6204 9.16448C14.6554 9.16674 14.6884 9.18163 14.7131 9.20637C14.7379 9.23111 14.7527 9.264
    14.755 9.29891L15.5532 10.6279C15.6869 10.859 15.7405 11.1276 15.7057 11.3923C15.671 11.6569 15.5498
    11.9026 15.3608 12.0914L14.8282 12.6906C14.8043 12.7162 14.7861 12.7465 14.7748 12.7796C14.7635 12.8127
    14.7594 12.8478 14.7627 12.8826L14.8282 13.0747C14.8957 13.5143 14.8519 13.9638 14.7007 14.3822C14.5496
    14.8005 14.2959 15.1744 13.9628 15.4696L12.6974 16.5989C12.4281 16.8295 12.2115 17.1152 12.0622
    17.4366C11.9129 17.7579 11.8345 18.1075 11.832 18.4618V19.7255C11.832 19.7735 11.93 20.4553 11.7262
    20.849C11.7011 20.8977 11.666 20.9405 11.6231 20.9746C11.5801 21.0086 11.5304 21.0331 11.4772
    21.0463C11.4241 21.0596 11.3687 21.0614 11.3148 21.0515C11.2609 21.0417 11.2097 21.0204 11.1646
    20.9892C11.0259 20.9132 10.904 20.8099 10.8065 20.6854C10.709 20.5609 10.6379 20.418 10.5974
    20.2652C10.4479 19.7338 10.2602 19.2139 10.0358 18.7095C9.92589 18.4862 9.85751 18.2448 9.83388
    17.997V14.2059C9.83388 14.1387 9.76653 14.0734 9.69922 14.0138L9.23385 13.7488C8.97075 13.5864 8.76909
    13.3414 8.6605 13.0522C8.5519 12.763 8.54259 12.446 8.63392 12.1509L8.70116 12.0184C8.71203 11.9248 8.68815
    11.8305 8.63392 11.7534C8.36316 11.4604 8.15944 11.1122 8.0369 10.7328C7.91437 10.3534 7.87587 9.95197
    7.92422 9.55626C7.97067 9.1511 8.05239 8.75076 8.16846 8.35977C8.17845 8.26623 8.15465 8.17221 8.10123
    8.09474L7.90892 7.90268C7.88774 7.8817 7.86101 7.86706 7.83192 7.86043C7.54325 7.77728 7.27262 7.64117
    7.03386 7.45904C6.87546 7.2735 6.67127 7.13249 6.44154 7.04996L6.04537 6.904C5.97807 6.904 5.91081 6.77148
    5.91081 6.71195C5.91081 6.59479 5.86081 6.5295 5.75888 6.51989C5.72011 6.51552 5.68158 6.50912 5.64348
    6.50069L4.10502 6.11659C3.99205 6.08247 3.88755 6.02503 3.79824 5.94799C3.70893 5.87095 3.63682 5.77604
    3.58659 5.66938C3.53637 5.56273 3.50914 5.44673 3.50668 5.3289C3.50423 5.21107 3.52658 5.09403 3.57232
    4.98539C3.63963 4.92009 3.5723 4.79333 3.50499 4.72035L2.62806 3.88876C1.08465 5.6973 0.170071 7.95686
    0.0214774 10.3285C-0.127116 12.7001 0.498358 15.0559 1.80399 17.0425C3.10962 19.0291 5.02501 20.5393 7.26288
    21.3466C9.50076 22.1539 11.9405 22.2148 14.2159 21.5201C16.4914 20.8254 18.4799 19.4125 19.8832
    17.4936C21.2865 15.5746 22.029 13.2529 21.9991 10.8768C21.9693 8.50076 21.1689 6.1984 19.7178 4.31515C18.2668
    2.4319 16.2434 1.06931 13.9512 0.431795L13.9762 0.412588Z"
      fill="#EDF2F7"
    />
    <path
      d="M3.59731 4.96043C3.55158 5.06908 3.52922 5.18611 3.53168 5.30394C3.53413 5.42177 3.56136 5.53778 3.61158
    5.64443C3.66181 5.75108 3.73392 5.846 3.82324 5.92304C3.91255 6.00008 4.01705 6.05752 4.13001 6.09163L5.66847
    6.47574C5.70657 6.48416 5.7451 6.49057 5.78387 6.49494C5.8858 6.51031 5.93581 6.5756 5.93581 6.687C5.93581 6.75229
    6.00315 6.87905 6.07046 6.87905L6.46662 7.02501C6.69636 7.10753 6.90045 7.24854 7.05885 7.43408C7.29761 7.61621
    7.56833 7.75233 7.85701 7.83547C7.8861 7.8421 7.91273 7.85674 7.93391 7.87773L8.12622 8.06978C8.17964 8.14726
    8.20353 8.24128 8.19354 8.33481C8.07748 8.7258 7.99575 9.12615 7.94931 9.53131C7.90096 9.92701 7.93936 10.3285
    8.0619 10.7079C8.18443 11.0873 8.38816 11.4355 8.65891 11.7284C8.71314 11.8055 8.73712 11.8998 8.72624
    11.9934L8.65891 12.126C8.56758 12.421 8.57689 12.7381 8.68549 13.0273C8.79408 13.3165 8.99584 13.5615 9.25894
    13.7238L9.72431 13.9889C9.79162 14.0561 9.85887 14.1214 9.85887 14.1809V18.0393C9.8825 18.287 9.95097 18.5284
    10.0608 18.7518C10.2853 19.2562 10.4729 19.7761 10.6224 20.3074C10.6628 20.4602 10.734 20.6032 10.8315
    20.7277C10.929 20.8521 11.0509 20.9555 11.1896 21.0315C11.2346 21.0627 11.2859 21.0839 11.3398 21.0938C11.3937
    21.1036 11.4491 21.1019 11.5023 21.0886C11.5555 21.0753 11.6052 21.0508 11.6481 21.0168C11.6911 20.9828 11.7262
    20.94 11.7512 20.8913C11.9551 20.4976 11.857 19.8158 11.857 19.7678V18.5041C11.8595 18.1498 11.938 17.8002 12.0873
    17.4788C12.2366 17.1575 12.4531 16.8718 12.7224 16.6411L13.9878 15.5119C14.3209 15.2167 14.5746 14.8428 14.7257
    14.4244C14.8769 14.0061 14.9207 13.5566 14.8531 13.117L14.7878 12.9249C14.7845 12.8901 14.7885 12.855 14.7998
    12.8219C14.8111 12.7888 14.8293 12.7584 14.8531 12.7328L15.3858 12.1336C15.5748 11.9449 15.6961 11.6991 15.7308
    11.4345C15.7656 11.1699 15.7119 10.9012 15.5781 10.6702L14.7801 9.34117C14.7778 9.30626 14.7629 9.27337 14.7381
    9.24863C14.7133 9.22389 14.6804 9.209 14.6454 9.20674L13.182 9.01469C13.1562 9.01705 13.1301 9.0137 13.1057
    9.00489C13.0813 8.99607 13.0591 8.98199 13.0408 8.96367C13.0224 8.94534 13.0083 8.92321 12.9995 8.89883C12.9906
    8.87446 12.9873 8.84844 12.9897 8.82263V8.35594C12.985 8.30659 12.9632 8.26042 12.9281 8.22537C12.893 8.19032
    12.8468 8.16857 12.7974 8.16388H12.0647C12.0298 8.16729 11.9946 8.16323 11.9615 8.15197C11.9283 8.14071 11.8979
    8.12251 11.8724 8.09859L11.1397 7.29964C11.114 7.27582 11.0836 7.2577 11.0505 7.24644C11.0173 7.23519 10.9822
    7.23106 10.9474 7.23434H7.81663C7.76722 7.22965 7.72092 7.20791 7.68583 7.17286C7.65073 7.13781 7.62902 7.09164
    7.62432 7.04229C7.60255 6.84883 7.51982 6.66725 7.38816 6.52368C7.2565 6.38011 7.08268 6.28196 6.89162
    6.24335L6.64156 6.19342L6.51273 6.17421C6.41081 6.16077 6.35892 6.09547 6.35892 5.98216V5.45017C6.35615 5.40894
    6.34208 5.36925 6.31836 5.3354C6.29463 5.30155 6.2621 5.27483 6.22427 5.25812L5.62622 5.06606C5.59468 5.05417
    5.56592 5.03588 5.5418 5.01236C5.51768 4.98883 5.49867 4.96057 5.48602 4.92936C5.47338 4.89815 5.46742 4.86466
    5.46837 4.83101C5.46932 4.79735 5.47718 4.76426 5.49156 4.73381L5.67044 4.40155C5.78235 4.1909 5.9358 4.00506
    6.12154 3.85521C6.30728 3.70536 6.5215 3.59461 6.75124 3.52963L7.08392 3.4336C7.29772 3.37089 7.52754 3.39434
    7.72423 3.4989C7.77132 3.52327 7.81421 3.55504 7.85118 3.59301C8.02485 3.81279 8.21793 4.01655 8.42811
    4.20182H8.49543C8.54498 4.19749 8.59144 4.17586 8.62661 4.14074C8.66178 4.10562 8.68341 4.05925 8.68774
    4.00977V3.2934C8.68774 3.22618 8.75504 3.16089 8.82042 3.10135L10.5512 1.97015C10.7854 1.82985 10.9693 1.61947
    11.077 1.36885C11.1847 1.11822 11.2107 0.840094 11.1512 0.573922C11.1557 0.504911 11.185 0.439852 11.2338
    0.390777C11.2825 0.341701 11.3475 0.311931 11.4166 0.30697L13.48 0.499024C13.6596 0.524549 13.8426 0.490831
    14.0012 0.402997C11.9802 -0.159337 9.84039 -0.132668 7.83409 0.479858C5.8278 1.09238 4.03878 2.26521 2.67812
    3.85996L3.55506 4.69155C3.59736 4.76069 3.66462 4.89513 3.59731 4.96043Z"
      fill="black"
    />
  </svg>
);

export default UsdFlagSVG;
