export enum ECurrency {
  USD = 'USD',
  ARS = 'ARS',
  MXN = 'MXN',
  COP = 'COP',
  CLP = 'CLP',
  UYU = 'UYU',
  PYG = 'PYG',
  PEN = 'PEN',
  CRC = 'CRC',
  BOB = 'BOB',
  PAB = 'PAB',
}
