import { AppContext } from 'contexts/AppContext';
import { CurrencyActionTypes } from 'contexts/reducers/curency.reducer';
import { useContext, useEffect, useState } from 'react';
import { ECurrency } from 'types/enum/currency';
import { ICurrencyCountry } from 'types/interfaces/currency';
import { fetchCurrencies, getCurrencyFallback } from 'utils/currencies';
import { getPref, setPref } from '../utils/prefs';

interface IReturnType {
  ready: boolean;
  availableCurrencies: ECurrency[];
}

export default function useGetAllCurrencies(): IReturnType {
  const currentCurrency = getPref();
  const { state, dispatch } = useContext(AppContext);
  const [ready, setReady] = useState<boolean>(false);
  const [currency, setCurrency] = useState<ECurrency>();
  const [availableCurrencies, setAvailableCurrencies] = useState<ECurrency[]>([]);
  const [currenciesCountries, setCurrenciesCountries] = useState<ICurrencyCountry[]>([]);

  useEffect(() => {
    const fetchAndSetCurrencies = async () => {
      const fetchedCurrenciesCountries: ICurrencyCountry[] = await fetchCurrencies();

      if (fetchedCurrenciesCountries?.length) {
        dispatch({ type: CurrencyActionTypes.setAll, currencies: fetchedCurrenciesCountries });
        setCurrenciesCountries(fetchedCurrenciesCountries);
        const currencies: ECurrency[] = fetchedCurrenciesCountries.map((el: ICurrencyCountry) => el.alias as ECurrency);

        setAvailableCurrencies(currencies);
      }
    };

    if (!availableCurrencies?.length && !currenciesCountries?.length) {
      fetchAndSetCurrencies();
    }
  }, [availableCurrencies, currenciesCountries, dispatch]);

  useEffect(() => {
    if (availableCurrencies?.length && currenciesCountries?.length && state.geoCountry?.loc) {
      if (!currentCurrency) {
        const currencyFallback: ECurrency = getCurrencyFallback(currenciesCountries, state.geoCountry.loc);
        setCurrency(currencyFallback);
      } else {
        setCurrency(currentCurrency as ECurrency);
      }
    }
  }, [availableCurrencies, currenciesCountries, state.geoCountry, currentCurrency]);

  useEffect(() => {
    if (currency) {
      dispatch({ type: CurrencyActionTypes.select, selected: currency });
      setPref(currency);
      setTimeout(() => setReady(true), 500);
    }
  }, [currency, dispatch]);

  return { ready, availableCurrencies };
}
