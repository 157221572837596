const UyuFlagSVG: React.FC = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9976 22C4.92379 22 -2.65494e-07 17.0762 0 11.0024C2.65494e-07 4.92862 4.92379 0.00483218
    10.9976 0.00483244C17.0714 0.00483271 21.9952 4.92862 21.9952 11.0024C21.9952 17.0762 17.0714 22 10.9976
    22Z"
      fill="white"
    />
    <path
      d="M7.20495 2.55606L7.28808 4.16784L8.86675 3.73768L7.96864 5.07639L9.51654 5.64206L7.98023 6.19635L8.90612
    7.54179L7.31844 7.09995L7.26866 8.71124L6.23603 7.44203L5.2296 8.70372L5.14646 7.09193L3.5678 7.52209L4.46591
    6.18339L2.91801 5.61771L4.45432 5.06342L3.52842 3.71798L5.11611 4.15982L5.16589 2.54853L6.19852 3.81774L7.20495
    2.55606Z"
      fill="#FFDA44"
    />
    <path
      d="M11.1606 0.00477383C11.1606 0.00477383 12.8462 -0.113686 15.3966 0.829593C17.3212 1.65447 18.8589 3.30405
    18.8589 3.30405H11.1606V0.00477383Z"
      fill="#338AF3"
    />
    <path
      d="M10.9976 5.50362H20.5281C20.5281 5.50362 21.0553 6.48608 21.3035 7.15326C21.5371 7.78113 21.7739 8.8029
    21.7739 8.8029H10.9976V5.50362Z"
      fill="#338AF3"
    />
    <path
      d="M0 11.0024L21.9952 11.0024C21.9538 12.5602 21.83 13.409 21.3073 14.8516H0.688223C0.133503 13.4006 0.0197157
    12.5648 0 11.0024Z"
      fill="#338AF3"
    />
    <path
      d="M1.81154 17.0511H20.1905C19.052 18.7169 17.9337 19.84 15.7957 20.9002H6.19478C4.94891 20.3504 3.02434
    18.9757 1.81154 17.0511Z"
      fill="#338AF3"
    />
  </svg>
);

export default UyuFlagSVG;
