import * as Sentry from '@sentry/nextjs';
import { ECurrency } from '../types/enum/currency';
import { ICurrencyCountry } from '../types/interfaces/currency';

const CHECKOUT_BFF_URL = process.env.NEXT_PUBLIC_BFF_CHECKOUT_URL as string;

const getAllCurrenciesQuery = `
  query {
    getAllCurrencies {
      alias
      country {
        locale
      }
    }
  }
`;

interface IResponseData {
  data: {
    getAllCurrencies: ICurrencyCountry[];
  };
}

export async function fetchCurrencies(): Promise<ICurrencyCountry[]> {
  try {
    const response = await fetch(CHECKOUT_BFF_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: getAllCurrenciesQuery,
      }),
    });

    const json: IResponseData = await response.json();
    return json.data.getAllCurrencies;
  } catch (error) {
    // TO DO: implement error handler
    Sentry.captureException(error);
    return [];
  }
}

export const getCurrencyFallback = (currenciesCountries: ICurrencyCountry[], locale: string): ECurrency => {
  const currency: ICurrencyCountry | undefined = currenciesCountries.find(
    (el: ICurrencyCountry) => el.country.locale.split('-')[1].toUpperCase() === locale.toUpperCase(),
  );

  return currency?.alias as ECurrency ?? currenciesCountries[0]?.alias as ECurrency ?? ECurrency.USD;
};
